<template>
  <div class="games" :style="{ '--height': mineHeight }">
    <van-nav-bar style="background-color: var(--navBg)">
      <van-icon slot="left" name="arrow-left" color="#fff" @click="$router.back()" />
      <!-- <template #right>
        <img class="rightIcon" :src="require('@/assets/Img/game/order.png')" @click="$router.push('/gamesOrder')" alt="">
      </template> -->
    </van-nav-bar>
    <div class="games_result">
      <div class="games_result_left">
        <div class="games_result_left_text games_result_left_top">[{{ listItemNext.expect }}]</div>
        <div class="games_result_left_text games_result_left_bottom">{{ $t('newTabbar.shopPage.game.match') }}</div>
      </div>
      <div class="triangle-left"></div>
      <div class="games_result_right">
        <div class="games_result_right_btn games_result_right_top"
          v-if="listItemNext.code_info && listItemNext.code_info['ds']">{{ $t('ds.name') }}</div>
        <div class="games_result_right_btn games_result_right_bottom"
          v-if="listItemNext.code_info && listItemNext.code_info['dx']">{{ $t('dx.name') }}</div>
      </div>
    </div>
    <div class="games_times">
      <div class="games_times_left">
        <div class="games_times_left_order">[{{ listItem.expect }}]</div>
        <div class="games_times_left_time">{{ $t('newTabbar.shopPage.game.matchTime') }}</div>
      </div>
      <div class="games_times_right">
        <div class="games_times_right_li" :class="{ 'games_times_right_num': item == ':' }"
          v-for="item, index in time.split('')" :key="index">
          {{ item }}
        </div>
      </div>
    </div>
    <div class="games_list">
      <div class="games_list_btn" v-for="item, index in listItem.odds" :key="index">
        <div class="games_list_btn_text" :class="{ active: activeIndex.indexOf(index) >= 0 }" @click="listClick(index)">{{
          item.name }}</div>
      </div>
    </div>
    <div class="games_imgs">
      <div class="games_imgs_list" v-for="(item, index) in gameList" :key="index">
        <div class="games_imgs_list_border">
          <img :src="item.pictures && item.pictures[0] ? $img(item.pictures[0]) : ''" alt="">
        </div>
      </div>
      <div class="games_imgs_list border_none" v-for="item in (4 - (gameList.length % 4))"></div>
    </div>
    <div class="games_footer">
      <div class="games_footer_content games_footer_top" :class="activeIndex.length > 0 ? 'open' : 'close'"
        v-if="activeIndex.length > 0">
        <van-stepper v-model="num" :min="0" />
        <div class="games_footer_top_money">{{ $t('newTabbar.shopPage.game.total', { __: $money(activeIndex.length * num) }) }}</div>
      </div>
      <div class="games_footer_content games_footer_bottom">
        <div class="games_footer_bottom_left">{{ $t('newTabbar.shopPage.game.balance') }}<span>{{ $formatNumber(userInfo.money || 0) }}</span></div>
        <div class="games_footer_bottom_right">
          <div class="games_footer_bottom_right_clear" @click="clearClick">{{ $t('newTabbar.shopPage.game.clear') }}</div>
          <div class="games_footer_bottom_right_submit" @click="submitClick">{{ $t('newTabbar.shopPage.game.submit') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { NavBar, icon, Stepper, Toast } from 'vant'
import { GET_GAMEIMGS, LOOTERY, LOTTERY_SEND } from '@/api'
import Timer from '@/utils/time.js'
import { mapGetters } from "vuex";
export default {
  components: {
    [NavBar.name]: NavBar,
    [icon.name]: icon,
    [Stepper.name]: Stepper,
    [Toast.name]: Toast
  },
  data() {
    return {
      time: "00:00:00",
      list: [],
      activeIndex: [],
      gameList: [],
      num: 1,
      timer: null,
      listItem: {},
      listItemNext: {},
      mineHeight: '50rem',
      code: ''
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  created() {
    this.$store.dispatch('getUserInfo');
    this.getGameImgs();
    this.code = this.$route.query.code;
    this.getLottery();
  },
  beforeDestroy() {
    this.timer?.clear();
  },
  mounted(){
  },
  methods: {
    listClick(index) {
      const findIndex = this.activeIndex.findIndex(item => item == index);
      if (findIndex < 0) {
        this.activeIndex.push(index);
      } else {
        this.activeIndex.splice(findIndex, 1);
      }
      if (this.activeIndex.length > 0) {
        this.$nextTick(() => {
          this.mineHeight = '100rem'
        })
        return
      }
      this.$nextTick(() => {
        this.mineHeight = '50rem'
      })
    },
    clearClick() {
      this.activeIndex = [];
      this.num = 1;
      this.mineHeight = '50rem'
    },
    async submitClick() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      const money = this.activeIndex.length * this.num;
      const obj = this.listItem;
      let ids = '';
      this.activeIndex.map((item) => {
        let items = obj?.odds.filter((val, num) => item == num)
        ids += items[0] && `${items[0].id},`
      })
      ids = ids.replace(/,$/, '');
      try {
        const res = await LOTTERY_SEND({
          expect: obj.expect,
          money: money,
          code: ids,
          type: this.code
        })
        Toast(res.msg);
        this.$nextTick(() => {
          this.activeIndex = [];
          this.num = 1;
          this.mineHeight = '50rem'
        })
        setTimeout(() => {
          this.$store.dispatch('getUserInfo')
        }, 1000);
      } catch (error) {
        console.error('submitClick', error);
        Toast(error?.msg)
      }
    },

    getMoney() {
      let money = 0;
      this.activeIndex.forEach(item => {
        const find = this.listItem.odds.filter((val, index) => index == item)
        money += find[0].rate * this.num;
      });
      // return this.activeIndex.length * this.num
      return money

    },
    async getGameImgs() {
      try {
        const { data } = await GET_GAMEIMGS();
        this.gameList = data?.lists
      } catch (error) {
        console.error('getGameImgs', error);
      }
    },
    async getLottery() {
      try {
        const res = await LOOTERY({type: this.code});
        const { lists } = res?.data
        this.list = lists;
        let listItem = lists[0] || {};
        this.listItemNext = lists[1] || {};
        let odds = Object.values(listItem.odds);
        this.listItem = { ...listItem, odds: [...odds] }
        this.timer = new Timer(res?.data.current_time, listItem.endtime, this.endTime, this.getTime);
        this.timer.start();
      } catch (error) {
        console.error('getLottery', error);
      }
    },
    endTime() {
      const _this = this;
      setTimeout(() => {
        _this.getLottery();
      }, 5 * 1000);
    },
    getTime(time) {
      this.time = time;
    },
  },
}
</script>
<style scoped lang="less">
@themeColor: #ff0000;
@white: #fff;

.widthCalc(@name, @width) {
  @{name}: calc(~"@{width}" / 16)
}

.flex(@jus: normal, @ali: normal) {
  display: flex;
  justify-content: @jus;
  align-items: @ali;
}

.backgroundColor(@colors) {
  background-color: @colors;
}

.glossy {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, .3), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, .12) 51%, rgba(0, 0, 0, .04)) !important;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.games {
  height: calc(100vh - calc(var(--height) / 16));
  overflow: hidden;
  overflow-y: scroll;
  .backgroundColor(#f3f3f3);
  .rightIcon{
    .widthCalc(width, 30rem);
    .widthCalc(height, 30rem);
    display: block;
}
  .van-nav-bar {
    /deep/ .van-nav-bar__content {
      background-color: var(--navBg);
      .widthCalc(height, 50rem)
    }

    /deep/ .van-nav-bar__title {
      color: @white;
    }
  }

  &_result {
    .widthCalc(padding, 8rem);
    .flex(normal, center);
    .backgroundColor(@white);

    &_left {
      .widthCalc(padding, 8rem);
      .widthCalc(border-radius, 10rem);
      background-image: linear-gradient(to right, #43a8ff, #359fd4, #36b5c8, #37deb2);
      flex: 1;

      &_text {
        color: @white;
        .widthCalc(padding-left, 8rem);
        .widthCalc(line-height, 24rem);
      }
    }

    .triangle-left {
      width: 0;
      height: 0;
      position: relative;
      left: -1px;
      border-top: calc(10rem / 16) solid transparent;
      border-bottom: calc(10rem / 16) solid transparent;
      border-left: calc(20rem / 16) solid #37deb2;
    }

    &_right {
      flex: 1;
      .flex(center, center);
      flex-wrap: wrap;

      &_btn {
        .widthCalc(margin, 4rem);
        .widthCalc(border-radius, 4rem);
        padding: calc(10rem / 16) calc(18rem / 16);
        // display: inline-flex;
        width: 100%;
        .flex(center,center);
      }

      &_top {
        color: @white;
        .backgroundColor(@themeColor);
        .glossy();
        .ellipsis();
      }

      &_bottom {
        color: @white;
        .backgroundColor(#00c0fd);
        .glossy();
        .ellipsis();
      }
    }
  }

  &_times {
    .flex();
    .widthCalc(padding-left, 8rem);
    .widthCalc(padding-right, 8rem);
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    .backgroundColor(@white);

    &_left {
      flex: 1;
      .widthCalc(padding, 8rem);

      &_order {
        .widthCalc(padding-left, 8rem);
        .widthCalc(line-height, 24rem);
      }

      &_time {
        .widthCalc(padding-left, 8rem);
        .widthCalc(line-height, 24rem);
      }
    }

    &_right {
      flex: 1;
      .flex(space-between, center);

      &_li {
        .widthCalc(padding-top, 8rem);
        .widthCalc(padding-bottom, 8rem);
        .widthCalc(padding-left, 6rem);
        .widthCalc(padding-right, 6rem);
        .widthCalc(margin-left, 2rem);
        .widthCalc(margin-right, 2rem);
        .backgroundColor(#37475a);
        color: @white;
        .widthCalc(font-size, 20rem);
        .widthCalc(border-radius, 4rem);
      }

      &_num {
        .backgroundColor(transparent);
        color: #37475a;
        padding: 0;
        margin: 0;
      }
    }
  }

  &_list {
    .flex();
    .widthCalc(padding, 8rem);
    padding-top: 0;
    flex-wrap: wrap;
    .backgroundColor(@white);

    &_btn {
      .widthCalc(padding, 8rem);
      padding-bottom: 0;
      width: 50%;
      width: calc((100% - calc(32rem / 16)) / 2);

      &_text {
        // border: 1px solid @themeColor;
        .flex(center, center);
        .widthCalc(padding, 11rem);
        .widthCalc(padding-left, 4rem);
        .widthCalc(padding-right, 4rem);
        .widthCalc(font-size, 16rem);
        .widthCalc(border-radius, 4rem);
        .backgroundColor(rgba(0,0,0,.1));
        color: #9e9e9e;
      }
      
      .active {
        .backgroundColor(#232f3e);
        color: @white;
      }
    }
  }

  &_imgs {
    .widthCalc(padding, 8rem);
    .widthCalc(padding-left, 4rem);
    .widthCalc(padding-right, 4rem);
    padding-top: 0;
    .flex(space-between);
    flex-wrap: wrap;

    &_list {
      .widthCalc(margin-top, 8rem);
      border: 1px solid #ccc;
      .widthCalc(border-radius, 8rem);
      padding: 1px;
      width: calc(calc(100% - calc(24rem / 16)) / 4);
      // height: calc(calc(100% - calc(24rem / 16)) / 4);
      height: calc(98rem / 16);

      &_border {
        border: 1px solid #ccc;
        .widthCalc(border-radius, 8rem);
        overflow: hidden;
        height: calc(100% - 1px);

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }

    .border_none {
      border: none;
      height: 0 !important;
    }
  }

  &_footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    &_content {
      .widthCalc(height, 50rem);
      .flex(space-between, center);
      padding: 0 calc(12rem / 16);
    }

    &_top {
      .backgroundColor(#37475a);

      /deep/ .van-stepper__input {
        .widthCalc(width, 100rem);
        .widthCalc(height, 40rem);
        .widthCalc(border-radius, 4rem);
        .backgroundColor(rgba(255, 255, 255, .07));
        color: @white;
        padding: 0 calc(12rem / 16);
      }

      /deep/ .van-stepper__minus,
      /deep/ .van-stepper__plus {
        .backgroundColor(transparent);
        color: @white;
      }

      &_money {
        color: @white;
        .widthCalc(padding-left, 4rem);
        .widthCalc(font-size, 14rem);
      }
    }


    &_bottom {
      .backgroundColor(#000);
      .widthCalc(height, 50rem);

      &_left {
        color: @white;
        .widthCalc(font-size, 14rem);

        span {
          color: #ff9800;
        }
      }

      &_right {
        .flex(normal, center);
        .widthCalc(font-size, 14rem);
        @padding: calc(4rem / 16) calc(16rem / 16);

        &_clear {
          color: #4caf50;
          padding: @padding;
          .widthCalc(line-height, 30rem);
        }

        &_submit {
          padding: @padding;
          .widthCalc(line-height, 30rem);
          .backgroundColor(@themeColor);
          color: @white;
          .widthCalc(border-radius, 3rem);
          .glossy();
        }
      }
    }
  }
}</style>