/**
 * current_time 当前时间
 * endtime 下注结束时间
 * opentime 开奖时间
 * onTimerEnd 倒计时结束的方法
 */
class Timer {
  constructor(current_time, endtime, onTimerEnd, onTick) {
    this.current_time = current_time;
    this.endtime = endtime;
    this.onTimerEnd = onTimerEnd;
    this.timerId = null;
    this.isRuning = false;
    this.countTime = 0,
      this.onTickTime = onTick
  }
  start() {
    if (!this.isRuning) {
      this.countTime = this.endtime - this.current_time;
      this.timerId = setInterval(() => {
        if (this.countTime == 0 || this.countTime < 0) {
          this.clear();
          this.onTimerEnd();
        } else {
          this.countTime--;
          // console.log(this.countTime, '???');
          this.onTickTime(this.formatTime(this.countTime));
        }
      }, 1000);
      this.isRunning = true;
    }
  }
  pause() {
    clearInterval(this.timerId);
    this.timerId = null;
    this.isRunning = false;
  }
  reset() {
    clearInterval(this.timerId);
    this.timerId = null;
    this.isRunning = false;
  }
  clear() {
    clearInterval(this.timerId);
    this.timerId = null;
    this.isRunning = false;
  }
  formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${remainingSeconds}`;
  }
}
export default Timer